import api from "modules/api";
import { config } from "consts/api";
import ENDPOINTS from "../consts/api";

class UnofficialWhatsAppAPI {
  static InitializeApi(suit_id) {
    const url = `${config.adminServer}/uo-whatsapp/initialize/${suit_id}`;
    return api("post", url, {});
  }
  static sendApi(suit_id, data) {
    const url = `${config.servUrlInvocations}/${suit_id}/send`;
    return api("post", url, data);
  }
  static getGroupsApi(suit_id) {
    const url = `${config.servUrlInvocations}/${suit_id}/uo-whatsapp/groups`;
    return api("get", url, {});
  }
  static sendLaterApi = (data) => {
    const url = `${ENDPOINTS.SEND_LATER.NOTIFY()}`;
    return api("post", url, data);
  };
}

export default UnofficialWhatsAppAPI;
